@import 'scss';

.circle-button-conatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  cursor: pointer;
  .button-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .button-label {
    @include body-5;
    margin-right: 12px;
  }
  .outer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    .arrow-icon {
      position: absolute;
    }
    .inner-button {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    &.type1 {
      border: 1px solid $black;
      .arrow-icon {
        .arrow-icon-group {
          stroke: $black;
        }
      }
      .inner-button {
        @include btn-mouse-leave;
      }
    }
    &.type2 {
      background-color: $white;
      .arrow-icon {
        .arrow-icon-group {
          stroke: $black;
        }
      }
      .inner-button {
        @include btn-mouse-leave($bgColor: $white);
      }
    }
    &.type3 {
      background-color: $black;
      .arrow-icon {
        .arrow-icon-group {
          stroke: $white;
        }
      }
      .inner-button {
        @include btn-mouse-leave($bgColor: $black);
      }
    }
    &.type4 {
      background-color: $white;
      .arrow-icon {
        .arrow-icon-group {
          stroke: $black;
        }
      }
      .inner-button {
        @include btn-mouse-leave;
      }
    }
  }
  &:hover {
    .outer-button {
      &.type1,
      &.type4 {
        .arrow-icon {
          .arrow-icon-group {
            stroke: $white;
          }
        }
        .inner-button {
          @include btn-mouse-enter($scale: 1.05);
        }
      }
      &.type2 {
        .arrow-icon {
          .arrow-icon-group {
            stroke: $black;
          }
        }
        .inner-button {
          @include btn-mouse-enter($bgColor: $light-grey, $scale: 1.05);
        }
      }
      &.type3 {
        .arrow-icon {
          .arrow-icon-group {
            stroke: $black;
          }
        }
        .inner-button {
          @include btn-mouse-enter($bgColor: $white, $scale: 1.05);
        }
      }
    }
  }
}
