@import 'scss';

.form-file-container {
  margin-bottom: 56px;
  overflow: scroll;
  z-index: 3;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  .file-title {
    @include caption-1;
    color: $blue-grey;
    margin-bottom: 24px;
    &.error {
      color: $pink-red;
    }
  }
}
