@import 'scss';

.download-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  .download-btn-label {
    @include body-5;
    margin-right: 12px;
  }
  .outer-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $black;
    .inner-button {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      @include btn-mouse-leave(0);
    }
    .download-icon {
      z-index: 3;
    }
  }
  &:hover {
    .outer-button {
      .inner-button {
        @include btn-mouse-enter(1, $blue-grey);
      }
    }
  }
  @include responsive('mobile') {
    width: 100%;
    .download-btn-label {
      max-width: 70%;
      margin-right: 0px;
      @include caption-1;
    }
  }
}
