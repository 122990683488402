@import 'scss';

.label {
  display: inline;
  background-color: $white-grey;
  border-radius: 6px;
  @include caption-1;
  color: $blue-grey;
  &.big {
    padding: 8px 16px;
    word-break: break-all;
  }
  &.small {
    padding: 4px 8px;
  }
}
