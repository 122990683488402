@import 'scss';

@for $i from 0 through 500 {
  .text-wrapper {
    .animated-text {
      &.y-#{$i} {
        transform: translateY(#{$i}px);
      }
    }
  }
}

@for $i from 0 through 500 {
  .text-wrapper {
    .animated-text {
      &.duration-#{$i} {
        transition-duration: #{$i * 100}ms !important;
      }
    }
  }
}

//! Our News 같은 텍스트 (각각의 텍스트가 세로로 배치되어있을 경우)
//! 텍스트를 감싸는 container에 'block' className을 추가해주세요
.block {
  .text-wrapper {
    overflow: hidden;
    .animated-text {
      display: block;
    }
  }
  &.show {
    .text-wrapper {
      .animated-text {
        @include text-appear;
      }
    }
  }

  .text-wrapper.show {
    .animated-text {
      @include text-appear;
    }
  }
}

//! 일반 문단 형태 텍스트 (각각의 텍스트가 가로로 배치되어있을 경우) && container에 'show' className이 붙는 경우
//! 텍스트를 감싸는 container에 'inline' className을 추가해주세요
.inline {
  overflow: hidden;
  .text-wrapper {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    // &:not(:last-child)::after {
    //   content: ' ';
    // }
    .animated-text {
      display: inline-block;
    }
  }
  &.show {
    .text-wrapper {
      .animated-text {
        @include text-appear;
      }
    }
  }
}

//! 일반 문단 형태 텍스트 (각각의 텍스트가 가로로 배치되어있을 경우) && text-wrapper에 'show' className이 붙는 경우
//! 텍스트를 감싸는 container에 'inline-block' className을 추가해주세요
.inline-block {
  overflow: hidden;
  .text-wrapper {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    // &:not(:last-child)::after {
    //   content: ' ';
    // }
    .animated-text {
      display: inline-block;
    }
    &.show {
      .animated-text {
        @include text-appear;
      }
    }
  }
}
