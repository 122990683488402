@import 'scss';

.content-header {
  &.small-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    .content-header-title {
      margin-bottom: 24px;
      @include headline-4;
    }
    .content-header-desc {
      display: block;
      width: 100%;
      height: 100%;
      @include body-6;
    }
  }

  &.big-screen {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .content-header-title {
      @include headline-1;
    }
    .content-header-desc {
      display: flex;
      flex-direction: column;
      width: 640px;
      @include subtitle-2;
    }
    @include responsive('desktop') {
      &.contact {
        .content-header-desc {
          width: 565px;
        }
      }
    }
    &.not-flex {
      display: inline-block;
      // justify-content: unset;
      .content-header-title {
        margin-bottom: 32px;
      }
    }
  }
}

@include responsive('mobile') {
  .not-flex {
    .content-header-title {
      margin-bottom: 16px !important;
    }
  }
}
