@import 'scss';

.layout-container {
  // background-color: $white;
  .layout-content-container {
    min-height: calc(100vh - 80px);

    @include responsive('mobile') {
      margin-top: calc(#{$mobile-header-height} + #{$mobile-margin});
      margin-bottom: 64px;
    }
    @include responsive('tablet') {
      margin-top: calc(#{$tablet-header-height} + #{$tablet-margin});
      margin-bottom: 64px;
      display: block;
    }
    @include responsive('desktop') {
      margin-top: calc(#{$desktop-header-height} + #{$desktop-margin});
      margin-bottom: 100px;
    }
    @include responsive('widescreen') {
      margin-top: calc(#{$big-screen-header-height} + #{$widescreen-margin});
      margin-bottom: 100px;
    }
    @include responsive('fullhd') {
      margin-top: calc(#{$big-screen-header-height} + #{$fullhd-margin});
      margin-bottom: 140px;
    }

    &.no-margin {
      margin-top: $big-screen-header-height;
      margin-bottom: 0;
      @include responsive('mobile') {
        margin-top: $mobile-header-height;
      }
      @include responsive('tablet') {
        margin-top: $tablet-header-height;
      }
      @include responsive('desktop') {
        margin-top: $desktop-header-height;
      }
    }
  }
  .cookie-container {
    position: sticky;
    bottom: 0px;
    width: 100%;
    z-index: 10;
    background-color: $cookie-grey;
    transition: all 0.5s ease-in-out;
    visibility: visible;
    .cookie-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1344px;
      min-height: 100px;
      margin: 0 auto;
      .cookie-content-left {
        white-space: pre-wrap;
        max-width: 620px;
        @include body-5;
        color: $white;
      }
      .cookie-content-right {
        .cookie-content-long-circle-button-div {
          display: inline-block;
          &.left {
            margin-right: 12px;
          }
        }
      }
    }
    &.false {
      opacity: 1;
    }
    &.true {
      opacity: 0;
      // visibility: hidden;
      display: none;
    }
  }
  &.height-zero {
    height: 0 !important;
    overflow: hidden !important;
  }
}

@include responsive('mobile') {
  .cookie-container {
    display: flex;
    align-items: center;
    .cookie-content {
      display: inline-block !important;
      width: calc(100% - 48px);
      margin: 26px 24px !important;
      .cookie-content-left {
        margin-bottom: 8px;
      }
      .cookie-content-right {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
@include responsive('tablet') {
  .cookie-container {
    .cookie-content {
      width: calc(100% - 44px);
      .cookie-content-left {
        max-width: 400px !important;
        padding-right: 40px;
        margin: 0 auto;
      }
    }
  }
}
@include responsive('desktop') {
  .cookie-container {
    .cookie-content {
      width: calc(100% - 64px);
    }
  }
}
@include responsive('widescreen') {
  .cookie-container {
    .cookie-content {
      width: calc(100% - 64px);
    }
  }
}
@include responsive('fullhd') {
}
