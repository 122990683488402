$cubicBezierAnimation: cubic-bezier(0.25, 0.74, 0.22, 0.99);
/* 순차적으로 나타나는 애니메이션 delay 적용 */
@for $i from 0 through 100 {
  .show {
    .delay-#{$i} {
      transition-delay: #{$i * 100}ms !important;
      -webkit-transition-delay: #{$i * 100}ms !important;
    }
  }
}

@mixin under-bar {
  .under-bar {
    height: 2px;
    margin-top: 5px;
    background: $black;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s ease-in-out 0s;
    &.bold {
      height: 4px;
      margin-top: 8px;
    }
  }
  &:hover {
    & > .under-bar {
      transform: scaleX(1);
      transition: transform 0.2s ease-in-out 0s;
      transform-origin: left;
    }
  }
}

/* 버튼 마우스 hover 애니메이션 */
@mixin btn-mouse-enter($scale: 1, $bgColor: $black, $duration: 0.5s, $delay: 0s) {
  transform: scale($scale);
  background-color: $bgColor;
  transition-property: transform, background-color;
  transition: all $duration $cubicBezierAnimation $delay;
}

@mixin btn-mouse-leave($scale: 0, $bgColor: transparent, $duration: 0.5s, $delay: 0s) {
  transform: scale($scale);
  background-color: $bgColor;
  transition-property: transform, background-color;
  transition: all $duration $cubicBezierAnimation $delay;
}

/* 이미지 hover 애니메이션 */
@mixin image-zoom($scale: 1.1, $duration: 0.5s) {
  transform: scale($scale);
  transition: transform $duration $cubicBezierAnimation 0s;
}

/* 텍스트 나타나는 효과 (opacity 변경 X) */
@mixin text-appear($duration: 0.5s, $delay: 0s) {
  transform: translateY(0px);
  transition-property: transform;
  transition: transform $duration $cubicBezierAnimation $delay;
}

@mixin text-disappear($duration: 0.5s, $delay: 0s) {
  transform: translateY(60px);
  transition-property: transform;
  transition: transform $duration $cubicBezierAnimation $delay;
}

/* 모듈 나타나는 효과 */
@mixin module-appear($duration: 1.2s, $delay: 0s) {
  transform: translateY(0px);
  opacity: 1;
  transition-property: transform, opacity;
  transition: all $duration $cubicBezierAnimation $delay;
}

@mixin module-disappear($y: 100px, $duration: 1.2s, $delay: 0s) {
  transform: translateY($y);
  opacity: 0;
  transition-property: transform, opacity;
  transition: all $duration $cubicBezierAnimation $delay;
}

@mixin change-text($margin-top: -10px) {
  overflow: hidden;
  .up {
    transition: 0.3s;
    margin-top: 0px;
  }
  &:hover {
    .up {
      margin-top: $margin-top;
    }
  }
}

@mixin show-mobile-menu($animationname: slow-up, $height: 300px) {
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: 80px;
  background-color: $white;
  animation: $animationname 0.5s 1; // ! 여기서 가져오는건 @keyframes slow-up 혹은 @keyframes slow-down 임. mixin을 가져오는게 아님.
}
@mixin show-modal($animationname: slow-up) {
  width: 100%;
  height: 100vh;
  // background-color: rgba(0, 0, 0, 0.5);
  background-color: $white;
  animation: $animationname 0.5s 1;
}

@mixin slow-up($height: 100px) {
  // * 믹스인해서 가져올때 쓰는 slow-up
  @keyframes slow-up-mixin {
    0% {
      opacity: 0;
      transform: translateY($height);
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin slow-down($height: 300px) {
  // * 믹스인해서 가져올때 쓰는 slow-down
  @keyframes slow-down-mixin {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY($height);
    }
  }
}

@mixin text-flip($height: -112px, $number: 4) {
  @keyframes text-flip-mixin {
    // * 100 / number(6) = 16 씩 높이가 바뀜
    // * 5% 마다 같게 구성
    0% {
      margin-top: -$height * $number;
    }
    5% {
      margin-top: -$height * ($number - 1);
    }
    25% {
      margin-top: -$height * ($number - 1);
    }
    30% {
      margin-top: -$height * ($number - 2);
    }
    50% {
      margin-top: -$height * ($number - 2);
    }
    55% {
      margin-top: -$height * ($number - 3);
    }
    75% {
      margin-top: -$height * ($number - 3);
    }
    80% {
      margin-top: -$height * ($number - 4);
    }
    99% {
      margin-top: -$height * ($number - 4);
    }
    100% {
      margin-top: -$height * ($number - 5);
    }
  }
}

@mixin img-flip($number: 4) {
  @keyframes img-flip-mixin-enginnering {
    0% {
      opacity: 1;
    }
    5% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes img-flip-mixin-sales {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes img-flip-mixin-marketing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes img-flip-mixin-design {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

// -------------------- key frames --------------------
@keyframes slow-down {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(300px);
  }
}

@keyframes slow-up {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slow-up-modal {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
  }
}

// -------------------- key frames --------------------
