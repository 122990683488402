@import 'scss';

.flowing-text-section {
  display: flex;
  white-space: nowrap;
  .flowing-text {
    color: $pale-blue-grey;
    @include responsive('mobile') {
      @include headline-3;
      margin-right: 32px;
      animation: flowing-mobile 10s linear infinite;
    }
    @include responsive('tablet') {
      @include flowing-text-1;
      margin-right: 85px;
      animation: flowing-tablet 10s linear infinite;
    }
    @include responsive('desktop') {
      @include flowing-text-2;
      margin-right: 120px;
      animation: flowing-big-screen 10s linear infinite;
    }
    @include responsive('widescreen') {
      @include flowing-text-2;
      margin-right: 120px;
      animation: flowing-big-screen 10s linear infinite;
    }
    @include responsive('fullhd') {
      @include flowing-text-2;
      margin-right: 120px;
      animation: flowing-big-screen 10s linear infinite;
    }
  }
}

// 흐르는 텍스트 keyframe
@keyframes flowing-mobile {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% - 32px), 0, 0);
  }
}

@keyframes flowing-tablet {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% - 85px), 0, 0);
  }
}

@keyframes flowing-big-screen {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% - 120px), 0, 0);
  }
}
