@import 'scss';

.advisors-section {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $white;
  @include responsive('mobile') {
    justify-content: flex-start;
    margin-top: 64px;
  }

  .advisors-content {
    width: 100%;
    .advisors-title-section {
      .advisors-title {
        color: $black;
      }
      .advisors-title-desc {
        @include body-1;
        color: $blue-grey;
      }
    }
    .advisors-module-section {
      .module-container {
        width: 230px;
        height: 100%;
        &:hover {
          .module-image {
            overflow: hidden;
            img {
              @include image-zoom($scale: 1.07);
            }
          }
        }
        .module-image {
          height: 200px;
          margin-bottom: 32px;
          cursor: pointer;
          overflow: hidden;
          img {
            width: 230px;
            height: 200px;
            @include image-zoom($scale: 1);
          }
        }
        .module-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          height: 256px;
          .module-descriptions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            .module-name {
              margin-bottom: 16px;
              @include subtitle-1;
            }
            .module-desc {
              margin-bottom: 4px;
              @include caption-1;
              color: $blue-grey;
            }
            .module-degree {
              @include caption-1;
              color: $blue-grey;
            }
          }
        }
      }
    }

    @include responsive('mobile') {
      padding: 64px 24px;
      .advisors-title-section {
        margin-bottom: 56px;
        .advisors-title {
          margin-bottom: 8px;
          @include subtitle-2;
        }
      }
      .advisors-module-section {
        width: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        scrollbar-width: none;
        .advisors-module-container {
          display: flex;
          flex-direction: row;
          width: 1344px;
          .module-container {
            width: 240px;
            &:not(:last-child) {
              margin-right: 24px;
            }
            &:hover {
              background-color: $carousel-hover;
            }
            .module-image {
              width: 240px;
              height: 138px;
              margin-bottom: 16px;
              img {
                width: 240px;
                height: 138px;
              }
            }
            .module-body {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              height: 100%;
              .module-descriptions {
                .module-name {
                  margin-bottom: 4px;
                  @include subtitle-3;
                }
                .module-desc {
                  margin-bottom: 0px;
                  @include caption-3;
                }
                .module-degree {
                  @include caption-3;
                }
              }
            }
          }
        }
      }
    }
    @include responsive('tablet') {
      width: $tablet-content;
      padding: 100px 0;
      .advisors-title-section {
        margin-bottom: 56px;
        .advisors-title {
          margin-bottom: 16px;
          @include headline-6;
        }
      }
    }
    @include responsive('desktop') {
      width: $desktop-content;
      padding: 100px 0 140px 0;
      .advisors-title-section {
        margin-bottom: 80px;
        .advisors-title {
          margin-bottom: 16px;
          @include headline-4;
        }
      }
    }
    @include responsive('widescreen') {
      width: $widescreen-content;
      padding: 100px 0 140px 0;
      .advisors-title-section {
        margin-bottom: 80px;
        .advisors-title {
          margin-bottom: 16px;
          @include headline-4;
        }
      }
    }
    @include responsive('fullhd') {
      width: $fullhd-content;
      padding: 140px 0 180px 0;
      .advisors-title-section {
        margin-bottom: 80px;
        .advisors-title {
          margin-bottom: 16px;
          @include headline-4;
        }
      }
      .advisors-module-section {
        .advisors-module-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}
