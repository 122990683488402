@import 'scss';

.outer-long-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 44px;
  text-align: center;
  overflow: hidden;
  // background-color: $black;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-radius: 22px;
  color: $white;
  cursor: pointer;
  .inner-long-button {
    position: absolute;
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: $blue-grey;
    @include btn-mouse-enter(0, $blue-grey, 0.6s);
  }
  &:hover {
    border-radius: 22px;
    .inner-long-button {
      border-radius: 50%;
      @include btn-mouse-enter(200, $blue-grey, 0.6s);
    }
  }
  &:active {
    border-radius: 22px;
    .inner-long-button {
      border-radius: 50%;
    }
  }
  .button-label {
    position: absolute;
    z-index: 3;
    @include caption-2;
    .disabled-icon {
      margin-right: 4px;
    }
    &.big {
      @include body-5;
    }
    &.medium {
      @include caption-1;
    }
  }
}
