@import 'scss';

.hover-cursor-container {
  width: 100%;
  height: 100%;
  // position: relative;
  cursor: none;
  .cursor {
    // position: fixed;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 70px;
    z-index: 99;
    @include body-5;
    pointer-events: none;
    &.visible {
      transform: scale(1);
      transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0s;
    }
    &.hide {
      transform: scale(0);
      transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0s;
    }
  }
}
