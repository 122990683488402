@import 'scss';

.ie-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 624px;
  width: 100%;
  margin: 0 auto;
  .ie-title {
    @include subtitle-2;
    color: $black;
    margin-bottom: 16px;
  }
  .ie-desc-container {
    margin-bottom: 48px;
    text-align: center;
    .ie-desc {
      @include body-1;
      color: $black;
    }
  }
  .download-chrome {
    @include subtitle-2;
    color: $white;
    padding: 22px 60px;
    background-color: $black;
    cursor: pointer;
  }
}

@include responsive('mobile') {
  .ie-container {
    text-align: center;
    padding: 0 24px;
    .ie-title {
      @include subtitle-3;
      margin-bottom: 24px;
    }
    .ie-desc-container {
      .ie-desc {
        @include body-4;
      }
    }
    .download-chrome {
      padding: unset;
      width: 100%;
      height: 56px;
      line-height: 56px;
    }
  }
}
