@import 'scss';
$headerPadding: 24px 0 16px 0;

.header-wrapper {
  background-color: #fff;
  // position: sticky;
  position: fixed;
  // width: 100%;
  width: 100%;
  top: 0px;
  z-index: 100;
  transition: all 0.3s ease;
  .header-container {
    // position: sticky;
    // top: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    margin: 0 auto;
    padding: 28px 0;
    // background-color: 미정;
    transition: all 0.3s ease;
    background-color: transparent;
    &.false {
      height: 0px;
    }
    .mobile-logo {
      width: 40px;
      transition: all 0.5s ease;
      cursor: pointer;
    }
    .menu-container {
      .menu-content {
        display: inline-block;
        vertical-align: middle;
        height: 29px;
        margin-right: 44px;
        color: $black;
        cursor: pointer;

        &.select {
          > .menu-text {
            color: $light-grey;
          }
          @include change-text(0px);
        }

        > .down {
          color: $light-grey;
        }

        @include body-2;
        @include change-text(-25px);
        &:last-child {
          margin-right: unset;
        }
      }
    }
    .hamburger-div {
      z-index: 100;
      // position: absolute;
      // right: 10px;
      // background-color: $white;
      border-radius: 50%;
    }
    &.desktop,
    &.tablet,
    &.mobile {
    }
  }
  &.widescreen,
  &.fullhd {
    &.true {
      opacity: 1;
      background-color: $white;
    }
    &.false {
      opacity: 0;
      height: 0;
      padding: 0;
    }
  }
  .submenu-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 13px 0;
    color: $blue-grey;
    border-top: 1px solid $header-border;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    .submenu-content {
      position: absolute;
      display: flex;
      .submenu-item {
        margin-right: 32px;
        // transition: opacity 0.5s ease-in-out;
        visibility: hidden;
        @include body-4;
        cursor: pointer;
        &:last-child {
          margin-right: unset;
        }
        &:hover {
          color: $black;
        }
        &.show {
          // opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &.white {
    background-color: $white;
  }
}

.mobile-menu-container {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  touch-action: none;
  z-index: 100;
  &.true {
    visibility: visible;
    @include show-mobile-menu(slow-up);
  }
  &.false {
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    @include show-mobile-menu(slow-down);
  }

  .mobile-menu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin: 0 auto;
    .mobile-logo {
      width: 40px;
      cursor: pointer;
    }
    // .mobile-menu-hambuer-div {
    //   position: absolute;
    //   right: 0;
    // }
  }
  .mobile-menu-content {
    width: 100%;
    .menu-content {
      display: flex;
      align-items: center;
      height: 48px;
      opacity: 1;
      margin-bottom: 4px;
      @include headline-6;

      &.false {
        // animation: slow-up 1.5s 1;
        // opacity: 1;
      }

      .menu-text {
        cursor: pointer;
        &.select {
          color: $light-grey;
        }
        &:hover {
          color: $light-grey;
        }
        &.move-down {
          transform: translateY(90px);
          transition: transform 1s cubic-bezier(0.25, 0.74, 0.22, 0.99) 0s;
        }
        &:not(.move-down) {
          transform: translateY(0px);
          transition: transform 1s cubic-bezier(0.25, 0.74, 0.22, 0.99) 0s;
        }
      }
    }
  }
  .contact-position {
    position: absolute;
    bottom: 17%;
    right: 24px;
  }
  .mobile-sub-menu-container {
    position: absolute;
    top: 0;
    left: 40px;
    @include subtitle-1;
    .mobile-sub-menu-content {
      cursor: pointer;
      height: 34px;
      margin-top: 4px;
    }
  }
}

//
// --------------------------------------------------------
//
@include responsive('mobile') {
  .header-wrapper {
    background-color: transparent;
    position: sticky;
    .header-container {
      width: calc(100% - 48px);
      height: 80px;
      padding: $headerPadding;
    }
  }

  .mobile-menu-container {
    .mobile-menu-top {
      width: calc(100% - 48px);
      padding: $headerPadding;
    }
    .mobile-menu-content {
      padding: 50px 40px 0 40px;
    }
  }
}
@include responsive('tablet') {
  .header-wrapper {
    background-color: transparent;
    // z-index: 101;
    .header-container {
      user-select: none;
      width: calc(100% - 64px);
      height: 80px;
      padding: $headerPadding;
    }
  }

  .mobile-menu-container {
    .mobile-menu-top {
      width: calc(100% - 64px);
      padding: $headerPadding;
    }
    .mobile-menu-content {
      padding: 116px 60px 0 60px;
    }
    .contact-position {
      right: 22px;
    }
    .mobile-sub-menu-container {
      left: 60px;
      @include subtitle-1;
    }
  }
}
@include responsive('desktop') {
  .header-wrapper {
    background-color: transparent;
    .header-container {
      width: calc(100% - 64px);
      padding: $headerPadding;
      height: 80px;
    }
  }

  .mobile-menu-container {
    .mobile-menu-top {
      width: calc(100% - 64px);
      padding: $headerPadding;
    }
    .mobile-menu-content {
      padding: 116px 60px 0 60px;
    }
    .contact-position {
      right: 32px;
    }
    .mobile-sub-menu-container {
      left: 60px;
      @include subtitle-1;
    }
  }
}

@include responsive('widescreen') {
  .header-wrapper {
    .header-container {
      width: calc(100% - 64px);
    }
  }
}

@include responsive('fullhd') {
  .header-container {
    width: calc(100% - 136px);
  }
}

@keyframes subMenu-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
