@mixin headline-1 {
  font-size: 96px;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: -1.5px;
}

@mixin headline-2 {
  font-size: 88px;
  font-weight: 900;
  line-height: 1.09;
  letter-spacing: -1px;
}

@mixin headline-3 {
  font-size: 60px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

@mixin headline-4 {
  font-size: 48px;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: normal;
}

@mixin headline-5 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -1px;
}

@mixin headline-6 {
  font-size: 34px;
  font-weight: 900;
  line-height: 1.18;
  letter-spacing: -0.5px;
}

@mixin headline-7 {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: normal;
}

@mixin subtitle-1 {
  font-size: 26px;
  font-weight: 900;
  line-height: 1.31;
  letter-spacing: -0.5px;
}

@mixin subtitle-2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.18px;
}

@mixin subtitle-3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

@mixin body-1 {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.44;
  letter-spacing: 0.15px;
}

@mixin body-2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  letter-spacing: 0.15px;
}

@mixin body-3 {
  font-size: 18px !important;
  font-weight: 900 !important;
  line-height: 1.44 !important;
  letter-spacing: 0.15px !important;
}

@mixin body-4 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
}

@mixin body-5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
}

@mixin body-6 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
}

@mixin caption-1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.25px;
}

@mixin caption-2 {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.4px;
}

@mixin caption-3 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.44;
  letter-spacing: 0.15px;
}

@mixin flowing-text-1 {
  font-size: 120px;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: -1.5px;
}

@mixin flowing-text-2 {
  font-size: 160px;
  font-weight: 900;
  line-height: 1.13;
  letter-spacing: -2px;
}
