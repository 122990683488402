@import 'scss';

.hamberger-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 99;
  .bar-group {
    .bar {
      transition: transform 0.4s;
      transform-origin: left;
    }
  }
  &.active {
    .bar-group {
      .bar {
        &.top {
          transform: rotate(45deg) translateX(-7px) translateY(-9.5px) scaleX(1.05);
        }
        &.bottom {
          transform: rotate(-45deg) translateX(-7px) translateY(9.5px) scaleX(1.05);
        }
      }
    }
  }
}
