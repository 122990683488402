@import 'scss';

.input-container {
  position: relative;
  width: 100%;
  .input {
    all: unset;
    width: 100%;
    height: 30px;
    padding-bottom: 6px;
    background-color: transparent;
    @include body-1;
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
  }
  .bar {
    position: absolute;
    width: 100%;
    bottom: 0.5px;
    border-bottom: 1px solid $light-grey;
    &.animated-bar {
      border-bottom: 2px solid $black;
      transform: scaleX(0);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
  }
  .input-label {
    position: absolute;
    left: 0;
    bottom: 8px;
    color: $blue-grey;
    @include body-1;
    transition-property: color, bottom, font-size;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    pointer-events: none;
  }
  .error-icon {
    position: absolute;
    right: 0px;
    bottom: 8px;
  }
  .error-msg {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: $pink-red;
    @include caption-3;
  }
  .input:focus {
    background-color: transparent;
  }
  .input:active {
    background-color: transparent;
  }
  .input:focus ~ .bar {
    transform: scaleX(1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  .input:focus ~ .input-label {
    bottom: 34px;
    @include caption-3;
    transition-property: bottom, font-size;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }

  &.disabled {
    .input {
      color: $blue-grey;
    }
    .input-label {
      bottom: 34px;
      @include caption-3;
    }
  }
  &.error {
    .input-label {
      bottom: 34px;
      @include caption-3;
      color: $pink-red;
      transition-property: color, bottom, font-size;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .bar {
      &.animated-bar {
        border-bottom: 2px solid $pink-red;
      }
    }
  }
  &.value-exist {
    .input-label {
      bottom: 34px;
      @include caption-3;
      transition-property: bottom, font-size;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
  }
}
