@import 'scss';

.input-box {
  margin-bottom: 56px;
  &.small {
    display: inline-block;
    width: 317px;
    &:first-child {
      margin-right: 24px;
    }
  }
}

@include responsive('tablet') {
  .input-box {
    &.small {
      width: 256px;
      &:first-child {
        margin-right: 22px;
      }
    }
  }
}
