@import 'scss';

.checkbox-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  .checkbox {
  }
  .checkbox-label {
    margin-left: 8px;
    color: $blue-grey;
    .label-item {
      &.link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.small {
      @include caption-3;
    }
    &.big {
      @include body-1;
    }
  }
}
