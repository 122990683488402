$white: #ffffff;
$black: #1f2122;
$blue-grey: #636e72;
$light-grey: #b2bec3;
$white-grey: #f3f7fa;
$pale-blue-grey: #e4eaef;
$cookie-grey: #7d8a8f;
$pink-red: #ff005c;
$news-hover: #f3f3f3;
$carousel-hover: #f7f7f7;
$header-border: #e4ebef;
$history-bg: #2c2e30;
$main-icon-default: #3c3e41;
$blue: #001aff;
$box-shadow-1: 0 2px 3px 0 rgba(60, 64, 67, 0.15), 0 6px 10px 4px rgba(60, 64, 67, 0.15);
