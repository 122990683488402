@import 'scss';

.modal-container {
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  touch-action: none;
  // overflow: hidden;
  &.true {
    visibility: visible;
    @include show-modal(slow-up-modal);
  }
  &.false {
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    @include show-modal(slow-down);
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 24px;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
