@import 'scss';

.footer-container {
  width: 100%;
  border-top: 1px solid $pale-blue-grey;
  background-color: $white;
  .footer-content {
    width: $fullhd-content;
    // height: 300px;
    margin: 0 auto;
    padding-top: 58px;
    padding-bottom: 50px;
    .footer-head-line {
      display: flex;
      align-items: center;
      height: 40px;
      .footer-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .footer-menu-left {
          .footer-menu-1 {
            position: relative;
            display: inline-block;
            margin-right: 44px;
            cursor: pointer;
            @include body-4;
            &:last-child {
              margin-right: 0;
            }
            @include under-bar;
            &.Career {
              &:after {
                position: absolute;
                top: 1px;
                right: -19px;
                padding: 2.5px 4px;
                content: 'N';
                color: $white;
                background-color: $black;
                font-size: 10px;
                width: 8px;
                line-height: 11px;
                border-radius: 50%;
              }
            }
          }
        }
        .footer-menu-right {
          @include body-4;
          .footer-menu-2 {
            display: inline-block;
            margin-right: 44px;
            cursor: pointer;
          }
        }
      }
    }
    .footer-body {
      margin-top: 16px;
      height: 111px;
      max-height: 140px;
      .body-bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 54px;
        margin-bottom: 16px;
        @include caption-1;
        .footer-menu-left {
          .footer-menu-1 {
            display: inline-block;
            margin-right: 24px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &.under-desktop {
              &:hover {
                color: $blue-grey;
              }
            }
            .footer-menu-span {
              position: relative;
              &.Career {
                &:after {
                  position: absolute;
                  top: -4px;
                  right: -19px;
                  padding: 2.5px 4px;
                  content: 'N';
                  color: $white;
                  width: 8px;
                  background-color: $black;
                  font-size: 10px;
                  line-height: 10px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
        .footer-menu-right {
          .footer-menu-2 {
            display: inline-block;
            margin-right: 24px;
            cursor: pointer;
          }
        }
      }
    }
    .footer-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .social-icons {
        .social-icon {
          display: inline-block;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 8px;
          }
          &:hover {
            & > svg > g > g {
              fill: $blue-grey;
            }
          }
        }
      }
    }
  }
  &.false {
    display: none;
  }
  &.not-show {
    display: none;
  }
}

.download-pdf-component {
  display: flex;
  align-items: center;
  .download-txt {
    @include body-5;
    margin-right: 12px;
    cursor: pointer;
  }
}

@include responsive('mobile') {
  .footer-container {
    .footer-content {
      width: calc(100% - 48px);
      margin: 0 auto;
      // height: 240px;
      padding-top: 30px;
      padding-bottom: 24px;
      .footer-body {
        height: unset;
      }
      .download-pdf-component {
        justify-content: flex-end;
        .download-txt {
          @include caption-1;
        }
      }
      .footer-bottom {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .company-inc {
          @include caption-1;
        }
        .social-icons {
          margin-top: 16px;
          .social-icon {
            &:not(:last-child) {
              margin-right: 16px;
            }
          }
        }
      }
      .footer-head-line {
        height: 25px;
      }
    }
  }
}
@include responsive('tablet') {
  .footer-container {
    .footer-content {
      width: calc(100% - 64px);
      // height: 240px;
      margin: 0 auto;
      padding-top: 30px;
      padding-bottom: 24px;
      .footer-body {
        height: unset;
      }
      .download-pdf-component {
        justify-content: flex-end;
        .download-txt {
          @include caption-1;
        }
      }
      .company-inc {
        @include caption-1;
      }
      .footer-head-line {
        height: 25px;
      }
    }
  }
}
@include responsive('desktop') {
  .footer-container {
    .footer-content {
      width: calc(100% - 64px);
      // height: 240px;
      margin: 0 auto;
      padding-top: 30px;
      padding-bottom: 24px;
      .footer-body {
        height: unset;
      }
      .download-pdf-component {
        justify-content: flex-end;
        .download-txt {
          @include caption-1;
        }
      }
      .company-inc {
        @include caption-1;
      }
      .footer-head-line {
        height: 25px;
      }
    }
  }
}
@include responsive('widescreen') {
  .footer-container {
    .footer-content {
      width: $widescreen-content;
    }
  }
}
@include responsive('fullhd') {
  .footer-container {
    .footer-content {
      .company-inc {
        @include body-5;
      }
      .social-icons {
        margin-right: 44px;
      }
    }
  }
}
