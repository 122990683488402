@import 'scss';

.custom-select {
  width: 100%;
  height: 36px;
  position: relative;
  .select-box-button {
    position: absolute;
    bottom: 0.5px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .select-box-label {
      position: absolute;
      left: 0;
      bottom: 8px;
      color: $blue-grey;
      @include body-1;
      transition-property: color, bottom, font-size;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      &.value-exist {
        bottom: 34px;
        @include caption-3;
        transition-property: bottom, font-size;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    .selected-item {
      position: absolute;
      left: 0;
      bottom: 8px;
      color: $black;
      @include body-1;
    }
    .toggle-icon {
      position: absolute;
      right: 0px;
      bottom: 8px;
    }
    .bar {
      position: absolute;
      width: 100%;
      bottom: 0.5px;
      border-bottom: 1px solid $light-grey;
      &.animated-bar {
        border-bottom: 2px solid $black;
        transform: scaleX(0);
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    &.open {
      .bar {
        transform: scaleX(1);
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
      .select-box-label {
        bottom: 34px;
        @include caption-3;
        transition-property: bottom, font-size;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    &:active {
      .toggle-icon {
        svg {
          g {
            & > :last-child {
              fill: $black;
            }
          }
        }
      }
    }
  }
  .select-box-items {
    position: absolute;
    bottom: -324px;
    width: 100%;
    height: 324px;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    overflow: scroll;
    z-index: 1;
    &::-webkit-scrollbar {
      display: none;
    }
    .select-box-item {
      padding: 10px 16px;
      @include body-4;
      cursor: pointer;
      &:hover {
        background-color: $pale-blue-grey;
      }
    }
  }
}

.default-select {
  width: 100%;
  height: 36px;
  position: relative;
  .default-select-box {
    all: unset;
    position: absolute;
    bottom: -4px;
    width: 100%;
    height: 36px;
    @include body-1;
    color: $black;
    z-index: 1;
  }
  .select-box-label {
    position: absolute;
    left: 0;
    bottom: 8px;
    color: $blue-grey;
    @include body-1;
    z-index: 0;
    transition-property: color, bottom, font-size;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    &.value-exist {
      bottom: 34px;
      @include caption-3;
      transition-property: bottom, font-size;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
  }
  .bar {
    position: absolute;
    width: 100%;
    bottom: 0.5px;
    border-bottom: 1px solid $light-grey;
    &.animated-bar {
      border-bottom: 2px solid $black;
      transform: scaleX(0);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
  }
  .toggle-icon {
    position: absolute;
    right: 0px;
    bottom: 8px;
  }
  .default-select-box:focus ~ .select-box-label {
    bottom: 34px;
    @include caption-3;
    transition-property: bottom, font-size;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  .default-select-box:focus ~ .bar {
    transform: scaleX(1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
}

.select-container {
  &.default-select {
    &.error {
      .select-box-label {
        bottom: 34px;
        @include caption-3;
        color: $pink-red;
        transition-property: color, bottom, font-size;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
      .bar {
        &.animated-bar {
          border-bottom: 2px solid $pink-red;
        }
      }
    }
    &.value-exist {
      .select-box-label {
        bottom: 34px;
        @include caption-3;
        color: $blue-grey;
        transition-property: color, bottom, font-size;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
      .bar {
        &.animated-bar {
          border-bottom: 2px solid $black;
        }
      }
    }
  }
  &.custom-select {
    &.error {
      .select-box-button {
        .select-box-label {
          bottom: 34px;
          @include caption-3;
          color: $pink-red;
          transition-property: color, bottom, font-size;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        }
        .bar {
          &.animated-bar {
            border-bottom: 2px solid $pink-red;
          }
        }
      }
    }
    &.value-exist {
      .select-box-button {
        .select-box-label {
          bottom: 34px;
          @include caption-3;
          color: $black;
          transition-property: color, bottom, font-size;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        }
        .bar {
          &.animated-bar {
            border-bottom: 2px solid $black;
          }
        }
      }
    }
  }
}
