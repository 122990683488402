@import 'scss';

.loading-container {
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 0 auto;
  .spin {
    margin-top: 30vh;
  }
  .uploading-message {
    @include subtitle-3;
    animation: flash 1.5s infinite;
  }
}

@include responsive('mobile') {
  .loading-container {
    .spin {
      width: 100%;
      margin-top: 10vh;
    }
  }
}

@keyframes flash {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
