@import 'scss';

.text-field-container {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  border: 1px solid $light-grey;
  .text-field {
    all: unset;
    width: 100%;
    height: 100%;
    @include body-1;
    background-color: $white;
    color: $black;
    overflow-wrap: break-word;
    &::-webkit-scrollbar {
      display: none;
    }
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
  }
}
