@import 'scss';

// font-display: fallback - 100ms 내외의 시간 동안만 block을 하고 기본폰트를 보여준다. 응답이 오면 해당 폰트로 swap 하지만 짧은 시간(3s)만 기다린다.
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Black.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Bold.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Medium.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Light.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Thin.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Roboto;
  src: local(※), url('./assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: normal;
}

* {
  margin: 0;
  box-sizing: border-box;
  word-break: keep-all;
  font-family: Roboto;
}

.flickity-enabled {
  &:focus {
    outline: unset;
  }
}
.flickity-viewport {
  &:focus {
    outline: unset;
  }
}

.flickity-button {
  display: none !important;
}
.flickity-page-dots {
  .dot {
    height: 4px !important;
    width: 40px !important;
    margin: 0 !important;
    border-radius: 0 !important;
    &.is-selected {
      background: $black !important;
      &::marker {
        opacity: 0.4 !important;
      }
    }
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  scroll-behavior: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  &.not-scroll {
    overflow: hidden;
  }
  &.height-zero {
    height: 0;
  }
}

.MathJax_Display {
  margin: unset !important;
}
