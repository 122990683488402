@import './color.scss';
@import './font.scss';
@import './animation.scss';

$mobileMax: 767px;
$tabletMax: 1023px;
$desktopMax: 1215px;
$widescreenMax: 1407px;

$mobile-content: 272px;
$tablet-content: 724px;
$desktop-content: 960px;
$widescreen-content: 1152px;
$fullhd-content: 1344px;
$newsdetail-content: 1200px;

$big-screen-header-height: 100px;
$desktop-header-height: 80px;
$tablet-header-height: 80px;
$mobile-header-height: 0px;

$fullhd-margin: 140px;
$widescreen-margin: 100px;
$desktop-margin: 100px;
$tablet-margin: 40px;
$mobile-margin: 40px;
@mixin responsive($device) {
  @if $device == 'mobile' {
    @media screen and (max-width: $mobileMax) {
      @content;
    }
  } @else if $device == 'tablet' {
    @media screen and (min-width: $mobileMax+1) and (max-width: $tabletMax) {
      @content;
    }
  } @else if $device == 'desktop' {
    @media screen and (min-width: $tabletMax+1) and (max-width: $desktopMax) {
      @content;
    }
  } @else if $device == 'widescreen' {
    @media screen and (min-width: $desktopMax+1) and (max-width: $widescreenMax) {
      @content;
    }
  } @else if $device == 'fullhd' {
    @media screen and (min-width: $widescreenMax) {
      @content;
    }
  }
}
