@import 'scss';

.tag {
  display: inline-block;
  box-sizing: border-box;
  @include responsive('mobile') {
    display: flex;
  }
  @include responsive('tablet') {
    display: flex;
  }
  justify-content: center;
  padding: 10px 20px;
  border-radius: 24px;
  height: 42px;
  cursor: pointer;
  min-width: fit-content;
  .tag-icon {
    display: inline;
    // height: 20px;
    margin-right: 4px;

    svg {
      vertical-align: middle;
    }
  }
  .tag-label {
    @include caption-1;
    display: inline;
    word-break: break-all;
    width: max-content;
  }
  &.not-checked {
    border: 1px solid $light-grey;
    background-color: $white;
    .tag-label {
      color: $blue-grey;
    }
    .tag-icon {
      svg {
        g {
          g {
            fill: $blue-grey;
            & > :last-child {
              transform-origin: center center;
              transition: transform 0.4s;
            }
          }
        }
      }
    }
  }
  &.checked {
    border: 1px solid $black;
    background-color: $black;
    .tag-label {
      color: $white;
    }
    .tag-icon {
      svg {
        g {
          g {
            fill: $white;
            & > :last-child {
              transition: transform 0.4s;
              transform-origin: center center;
              transform: rotate(90deg) translateX(-1px) translateY(2px);
            }
          }
        }
      }
    }
  }
}
