@import 'scss';

.input-type-file-container {
  position: relative;
  display: inline-block;
  .file-selector-label {
    display: inline-flex;
    align-items: center;
    color: $blue-grey;
    @include caption-1;
    padding: 10px 20px 10px 20px;
    border: 1px solid $pale-blue-grey;
    border-radius: 24px;
    margin-right: 8px;
    > svg {
      margin-right: 4px;
    }
    @include responsive('mobile') {
      margin-bottom: 16px;
    }
    @include responsive('mobile') {
      width: 120px;
      margin-right: 0;
    }
  }

  .input-file {
    cursor: pointer;
    width: 115px;
    height: 42px;
    opacity: 0;
    position: absolute;
    left: 0;
    @include responsive('mobile') {
      width: 120px;
    }
  }

  .file-name-div {
    .delete-file-div {
      display: flex;
      align-items: center;
      margin-right: 4px;
      &:hover {
        cursor: pointer;
      }
    }

    display: inline-flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: $black;
    color: $white;
    border-radius: 24px;
    max-width: 320px;
    overflow: hidden;
    @include responsive('mobile') {
      margin-left: 8px;
    }
  }
  &:not(.exist) {
    &:hover {
      .file-selector-label {
        color: $black;
        border-color: $blue-grey;
        > svg {
          g {
            fill: $black;
          }
        }
      }
    }
  }
}
