@import 'scss';

.carousel-container {
  padding-top: 140px;
  .carousel-title {
    @include headline-3;
    max-width: 1144px;
    margin: 0 auto;
  }
  .carousel-module-container {
    position: relative;
    width: 100%;
    // height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 80px;
    .carousel-module-container-inner-div {
      .carousel-btn-div {
        position: absolute;
        top: 50%;
        z-index: 100;
        transition: all 0.3s ease-in-out;
        background-color: transparent;
        border-radius: 50%;
        opacity: 0.4;
        cursor: pointer;
        &.left {
          left: 100px;
          &.true {
            visibility: visible;
          }
          &.false {
            visibility: hidden;
            opacity: 0;
          }
        }
        &.right {
          right: 100px;
          &.true {
            visibility: visible;
          }
          &.false {
            opacity: 0;
            visibility: hidden;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
      .carousel-slide-container {
        position: relative;
        width: 100%;
        height: 660px;
        overflow: hidden;
        .carousel-slide-ul {
          position: absolute;
          // width: calc(#{($slide-count) * $slide-width}); // 적당한 길이를 갖기 위해 사용
          margin: 0;
          padding: 0;
          transition: all 0.5s ease-in-out;
          list-style-type: none;
          .carousel-slide-li {
            display: inline-block;
            width: 552px;
            margin-right: 40px;
            margin-bottom: 50px;
            transition: left 0.3s;
            border: 1px solid $pale-blue-grey;
            overflow: hidden;
            .carousel-slide-img-div {
              overflow: hidden;
              .carousel-slide-img {
                width: 100%;
                height: 313px;
                max-height: 313px;
                min-height: 254px;
                object-fit: cover;
                @include image-zoom(1);
                &:hover {
                  @include image-zoom(1.1);
                }
              }
            }

            &:last-child {
              margin-right: unset;
            }

            .carousel-contents-div {
              padding: 32px;
              > .label {
                display: inline-block;
                margin-bottom: 16px;
                margin-right: 8px;
              }
              .carousel-contents-title {
                display: -webkit-box;
                margin-bottom: 40px;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @include headline-6;
                &.teams {
                  margin-bottom: 16px;
                }
              }
              .carousel-contents-desc {
                color: $blue-grey;
                @include body-1;
                margin-bottom: 40px;
              }
              .carousel-circle-button-div {
                display: flex;
                justify-content: flex-end;
              }
              &.teams {
                height: 322px;
              }
            }
            &:hover {
              box-shadow: $box-shadow-1;
              border-color: $white;

              .carousel-slide-img {
                @include image-zoom(1.1);
              }
            }
            &.desktop,
            &.tablet,
            &.mobile {
              &:hover {
                background-color: $carousel-hover;
              }
            }
          }
          > .flickity-button {
            display: none;
          }
        }
      }
    }
  }
}

@include responsive('mobile') {
  .carousel-container {
    padding: 64px 0;
    .carousel-title {
      @include headline-6;
      margin: 0 24px;
    }
    .carousel-module-container {
      margin-top: 64px;
      height: unset;
      .carousel-module-container-inner-div {
        height: unset;
        .carousel-slide-container {
          height: 320px;
          .carousel-slide-ul {
            .carousel-slide-li {
              width: 240px;
              margin-right: 24px;
              border: unset;
              margin-bottom: unset;
              .carousel-slide-img {
                min-height: 138px !important;
                max-height: 138px !important;
              }
              .carousel-contents-div {
                padding: 16px 0 0 0;
                height: unset;
                .carousel-contents-title {
                  @include subtitle-3;
                  line-clamp: 3;
                  -webkit-line-clamp: 3;
                  min-height: 84px;
                  &.teams {
                    min-height: unset;
                    margin-bottom: 8px;
                  }
                }

                .carousel-circle-button-div {
                  display: none;
                }
                > .label {
                  // width: 97px;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  font-size: 13px !important;
                  padding: 4px 8px;
                }
              }
              &:hover {
                box-shadow: unset;
              }
            }
          }
          &.teams {
            overflow: unset !important;
          }
        }
      }
    }
  }
}
@include responsive('tablet') {
  .carousel-container {
    padding: 100px 0;
    .carousel-title {
      margin: 0 56px;
    }
    .carousel-module-container {
      margin-top: 64px;
      height: unset;
      .carousel-module-container-inner-div {
        height: unset;
        .carousel-slide-container {
          .carousel-slide-ul {
            .carousel-slide-li {
              .carousel-slide-img-div {
                .carousel-slide-img {
                  max-height: 254px;
                }
              }
              .carousel-contents-div {
                &.teams {
                  height: 348px;
                }
              }
              &.tablet {
                width: 456px;
              }
            }
          }
        }
      }
    }
  }
}
@include responsive('desktop') {
  .carousel-container {
    padding-top: 100px;
    .carousel-title {
      margin: 0 56px;
    }
    .carousel-module-container {
      margin-top: 64px;
      .carousel-module-container-inner-div {
        .carousel-slide-container {
          .carousel-slide-ul {
            .carousel-slide-li {
              .carousel-slide-img-div {
                .carousel-slide-img {
                  max-height: 254px;
                }
              }
              .carousel-contents-div {
                &.teams {
                  height: 348px;
                }
              }
              &.desktop {
                width: 456px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive('widescreen') {
  .carousel-container {
    padding-top: 100px;
    .carousel-title {
      width: calc(90% - 156px);
      margin: 0 auto;
    }
    .carousel-module-container {
      .carousel-module-container-inner-div {
        width: calc(90% - 156px);
      }
    }
  }
}

//* team advisor 부분 코드
.carousel-container {
  // height: 100%;
  &.team-advisor {
    padding-top: 0px;
    padding-bottom: 0px;
    .carousel-module-container {
      width: 100%;
      // height: 100%;
      margin-top: 0;
      .carousel-module-container-inner-div {
        // height: 100%;
        .carousel-slide-container {
          height: 490px;
          .carousel-slide-ul {
            display: flex;
            flex-direction: row;
            height: 100%;
            & > :not(:last-child) {
              margin-right: 48px;
            }
          }
        }
      }
    }
  }
}
