@import 'scss';

.message-container {
  @include body-4;
  z-index: 101;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: all 1s ease-in-out;
  opacity: 1;
  .text-div {
    background-color: #424242;
    color: $white;
    border-radius: 4px;
    .message-text {
      text-align: center;
      min-width: 87px;
      padding: 14px 40px;
    }
  }

  &.appear {
    animation: down 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  &.disappear {
    animation: up 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    // * constants/message.js 의 messageAnimationEndTime 의 마이너스 값 (ex:-1000)과 같아야함. (1000 이면 1s, 1500 이면 1.5s)
  }
  &.false {
    opacity: 0;
  }
}

@include responsive('tablet') {
  .message-container {
    top: unset;
    bottom: 100px;
  }
}
@include responsive('mobile') {
  .message-container {
    top: unset;
    bottom: 100px;
  }
}

@keyframes down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes up {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
