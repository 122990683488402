@import 'scss';

.long-square-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 22px 0;
  background-color: $black;
  cursor: pointer;
  .long-square-button-label {
    margin-right: 8px;
    @include subtitle-2;
    color: $white;
    cursor: pointer;
  }
  .long-square-button-icon {
    transform: translateY(3.5px);
  }
  &.small {
    padding: 16px 0;
    .long-square-button-label {
      margin-right: 8px;
      @include body-5;
      color: $white;
      cursor: pointer;
    }
    .long-square-button-icon {
      pointer-events: none;
    }
  }
}
