@import 'scss';

.content-wrapper {
  color: $black;
  width: 600px;
  @include responsive('mobile') {
    width: 100%;
    padding: 0 24px;
  }
  // @include responsive('tablet') {
  //   width: $tablet-content;
  // }
  // @include responsive('desktop') {
  //   width: $desktop-content;
  // }
  // @include responsive('widescreen') {
  //   width: $widescreen-content;
  // }
  // @include responsive('fullhd') {
  //   width: $fullhd-content;
  // }

  &.mobile {
    .title-contanier {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 64px;
      @include headline-6;
    }
    .content-contanier {
      .content-section {
        .content-title {
          @include subtitle-3;
        }
        .content-desc-para {
          .list-item-container {
            position: relative;
            margin-top: 24px;
            .dot {
              position: absolute;
              top: 10px;
              left: 16px;
              width: 4px;
              height: 4px;
              border-radius: 2px;
              background-color: $black;
            }
            .list-item {
              padding-left: 28px;
            }
          }
        }
      }
    }
  }

  .title-contanier {
    display: flex;
    justify-content: center;
    @include responsive('tablet') {
      margin-bottom: 64px;
      @include headline-4;
    }
    @include responsive('desktop') {
      margin-bottom: 100px;
      @include headline-3;
    }
    @include responsive('widescreen') {
      margin-bottom: 100px;
      @include headline-3;
    }
    @include responsive('fullhd') {
      margin-bottom: 140px;
      @include headline-2;
    }
  }

  .content-contanier {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    .content-section {
      opacity: 0;
      &:not(:last-child) {
        margin-bottom: 56px;
      }
      &:last-child {
        margin-bottom: 100px;
      }
      &.show {
        @include slow-up(200px);
        opacity: 1;
        animation: slow-up-mixin 1.7s cubic-bezier(0.25, 0.74, 0.22, 0.99);
      }
      &:not(.show) {
        transform: translateY(200px);
        transition: all 1.7s cubic-bezier(0.25, 0.74, 0.22, 0.99);
      }
      .content-title {
        @include subtitle-2;
        margin-bottom: 24px;
      }
      .content-desc-para {
        margin-bottom: 24px;
        // width: 600px;
        @include body-1;
        .link,
        .email {
          @include body-2;
          text-decoration: underline;
          color: $blue;
        }
        .list-container {
          position: relative;
          margin-top: 24px;
          .dot {
            position: absolute;
            top: 10px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: $black;
          }
          .list {
            padding-left: 12px;
          }
        }
        .list-item-container {
          position: relative;
          margin-top: 24px;
          .dot {
            position: absolute;
            top: 10px;
            left: 40px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: $black;
          }
          .list-item {
            padding-left: 52px;
          }
        }
      }
    }
  }
}
